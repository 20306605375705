<template>
  <v-container fluid fill-height>
    <v-row class="d-flex">
      <v-col class="align-self-center">
        <v-sheet
          max-width="374"
          class="mx-auto"
        >
          <v-card-text>
            <v-form>
              <v-row dense>
                <v-col cols="12">
                  <v-text-field
                    v-model="form.username"
                    :disabled="loading"
                    autocomplete="username"
                    color="black"
                    prepend-inner-icon="mdi-account"
                    dense
                    outlined
                    required
                    hide-details
                    autofocus
                    @keypress.enter="login()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="form.password"
                    :disabled="loading"
                    autocomplete="current-password"
                    color="black"
                    prepend-inner-icon="mdi-key"
                    type="password"
                    dense
                    outlined
                    required
                    hide-details
                    @keypress.enter="login()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="d-flex justify-center align-center">
                  <v-btn
                    :disabled="!form.username || !form.password"
                    :loading="loading"
                    color="primary"
                    @click="login()"
                  >
                    Login
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import swal from 'sweetalert2'
  export default {
    data: () => ({
      form: {
        username: null,
        password: null,
      },
      loading: false,
    }),
    methods: {
      login() {
        if (this.form.username && this.form.password) {
          this.loading = true

          this.$store.dispatch('login', this.form).then(response => {
            this.loading = false
            
            this.$router.push('/home')
          }).catch(error => {
            console.log(error)
            swal.fire({
              title: 'Error!',
              text: error.response.data.error,
              icon: 'error',
            })

            this.form.password = null
            this.loading = false
          })
        }
      },
    },
  }
</script>
<style scoped>
.centered-input >>> input {
    text-align: center;
}
</style>