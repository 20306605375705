<template>
  <SystemSelection />
</template>

<script>
  import SystemSelection from '../components/SystemSelection.vue'

  export default {
    name: 'system-selection',

    components: {
      SystemSelection,
    },
  }
</script>
