import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import Users from '@/views/Users.vue'
import SystemSelection from '@/views/SystemSelection.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/home',
    name: 'system-selection',
    component: SystemSelection,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/users',
    name: 'users',
    component: Users,
    meta: {
      requiresAuth: true,
    },
  },
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.meta.requiresAuth) {
    if (new Date(localStorage.getItem('token_expiration')) <= new Date()) {
      next('/')
    } else {
      next()
    }
  } else {
    if (to.path === '/' && new Date(localStorage.getItem('token_expiration')) > new Date()) {
      next('/home')
    } else {
      next()
    }
  }
})

export default router